import { FC } from 'react';

import BodyText from 'components/atoms/BodyText';
import { ISectionTitleProps } from 'components/molecules/SectionTitle/types';
import { colors } from 'styles/variables.module';
import {
  HeadingCategoryStyleWrapper,
  SectionTitleStyleWrapper
} from './styles';

const SectionTitle: FC<ISectionTitleProps> = ({
  title,
  subTitle,
  titleLevel = 4
}) => {
  return (
    <SectionTitleStyleWrapper>
      <HeadingCategoryStyleWrapper
        size={'large'}
        level={titleLevel}
        fontWeight="bold"
        text={title}
      />
      <BodyText color={colors.gray_8} size="base" text={subTitle} />
    </SectionTitleStyleWrapper>
  );
};

export default SectionTitle;
